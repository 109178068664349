import router from "@/router";
import ApiService from "../services/ApiService";
import { Notify } from "vant";
import axiosConnection from "@/services/dbConnection";
import * as Constants from "./constants";

function default_state() {
  return {
    token: null,
    user_id: null,
    userToken: null,
    subUserToken: null,
    user: null,
    parent: {},
    parent_id: null,
    userId: null,
    userRoleId: null,
    schoolCode: null,
    subAccount: null,
    device_session: null,
    children: null,
    registrationInProgress: null,
    currentToken: null,
    selectedAccountType: null,
    parent2_token: null,
    available_schools: [],
    user_organizations: [],
    loading: false,
    loading_started: null,
    term: "",
    native: false,
    version: 4,
    show_new_features: 0,
    schoolrunFeatures: [],
    dataUpdated: {
      yearLabels: 133423, // unix timestamp
    },
    yearLabels: {},
    should_refresh_messages: false,
    show_congrats_page: false,
    roles: []
  };
}

export default {
  state: default_state(),
  getters: {},
  mutations: {
    [Constants.LOGGED_IN](state, payload) {
      try {
        localStorage.clear();
        state.token = payload.token;
        state.userToken = payload.token;
        state.user = payload.user;
        state.roles = payload.roles;

        if (payload.roles?.length > 0) {
          state.userRoleId = payload.roles[0].user_role_id;
        }
        // state.subAccount = payload.subAccount;
        // state.subUserToken = payload.subUserToken;
        state.device_session = payload.device_session;
        state.schoolCode = null; // clear school code
        if (payload.available_schools) {
          state.available_schools = payload.available_schools;
        }
        if (payload.version) {
          state.version = payload.version;
        }
        if (payload.available_schools && payload.available_schools.length > 0) {
          state.show_new_features =
            payload.available_schools.filter(
              (itm) => itm.school.show_new_features === 1
            ).length > 0
              ? 1
              : 0;
        }
        localStorage.setItem("token", payload.token);
        localStorage.setItem("user_id", parseInt(payload.user.id));
        state.user_id = parseInt(payload.user.id);
        axiosConnection.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${payload.token}`;
        // axiosConnection.interceptors.request.use(
        //   function (config) {
        //     config.params['blah-defaut-param'] = 'blah-blah-default-value';
        //     return config;
        //   }, function (error) {
        //     return Promise.reject(error);
        //   });
        //   state.posts = payload;
      } catch (error) {
        console.log("axios login error", error);
      }
    },
    [Constants.LOGGED_OUT](state) {
      const initial = default_state();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });

      localStorage.clear();

      axiosConnection.defaults.headers.common["Authorization"] = undefined;
    },
    [Constants.UPDATE_USER_INFO](state, payload) {
      state.user = {
        salutation: payload.salutation,
        first_name: payload.first_name,
        last_name: payload.last_name,
        profile_photo_url: payload.profile_photo_url,
        // sharing_journey: payload.sharing_journey,
        // is_staff: payload.is_staff,
        status: payload.status,
        // parents_reply: payload.parents_reply,
      };

      try {
        state.children = payload.children;
        state.roles = payload.roles;
        if (!state.userRoleId) {
          if (payload.roles?.length > 0) {
            state.userRoleId = payload.roles[0].user_role_id;
          }
        }
        if(state.userRoleId && !payload.roles.find((itm) => itm.user_role_id == state.userRoleId)){
          if (payload.roles?.length > 0) {
            state.userRoleId = payload.roles[0].user_role_id;
          }
        }
        // state.available_schools = payload.available_schools;
        state.user_organizations = payload.user_organizations;
        if (
          payload.user_organizations &&
          payload.user_organizations.length > 0
        ) {
          state.show_new_features =
            payload.user_organizations.filter(
              (itm) => itm.organization.show_new_features === 1
            ).length > 0
              ? 1
              : 0;
        }
        state.user_id = parseInt(payload.id);
      } catch (error) {
        alert(error);
      }
    },
    // [Constants.OLD_UPDATE_USER_INFO](state, payload) {
    //   state.user = {
    //     salutation: payload.salutation,
    //     first_name: payload.first_name,
    //     last_name: payload.last_name,
    //     profile_photo_url: payload.profile_photo_url,
    //     intro_seen: payload.intro_seen,
    //     last_viewed: payload.last_viewed,
    //     not_a_parent: payload.not_a_parent,
    //     sharing_journey: payload.sharing_journey,
    //     is_pta: payload.is_pta,
    //     is_staff: payload.is_staff,
    //     status: payload.status,
    //     parents_reply: payload.parents_reply,
    //   };

    //   try {
    //     state.parent = payload.profiles[0];
    //     state.parent_id = parseInt(payload.profiles[0].id);
    //     state.children = payload.children;
    //     state.available_schools = payload.available_schools;
    //     if (payload.available_schools && payload.available_schools.length > 0) {
    //       state.show_new_features =
    //         payload.available_schools.filter(
    //           (itm) => itm.school.show_new_features === 1
    //         ).length > 0
    //           ? 1
    //           : 0;
    //     }
    //     if (payload.sub_account) {
    //       if (
    //         !state.subAccount ||
    //         state.subAccount.id != payload.sub_account.id
    //       ) {
    //         // switch tokens
    //         let tempToken = state.userToken;
    //         state.userToken = state.subUserToken;
    //         state.subUserToken = tempToken;
    //       }
    //       state.subAccount = payload.sub_account;
    //     } else {
    //       state.subAccount = null;
    //       state.subUserToken = null;
    //     }
    //     state.user_id = parseInt(payload.id);
    //     state.parent_id = parseInt(payload.profiles[0].id);
    //   } catch (error) {
    //     alert(error);
    //   }
    // },
    [Constants.LOADING](state, bool) {
      if (bool === true) {
        state.loading_started = Math.round(new Date().getTime() / 1000);
      }
      state.loading = bool;
    },
    [Constants.REFRESH_MESSAGES](state, bool) {
      state.should_refresh_messages = bool;
    },
    [Constants.PARENT2_TOKEN](state, parent2Token) {
      state.parent2_token = parent2Token;
    },
    [Constants.SCHOOL_CODE](state, schoolCode) {
      state.schoolCode = schoolCode;
    },
    [Constants.UPDATE_JOURNEY_PREFERENCE](state, journey_preference) {
      state.parent.journey_preference = journey_preference;
    },
    [Constants.UPDATE_USER_ROLE_SETTING](state, payload) {
      const currentRoles = state.roles;
      currentRoles.forEach((role) => {
        if (role.user_role_id == payload.user_role_id) {
          role[payload.field] = payload.value
        }
      })
      state.roles = JSON.parse(JSON.stringify(currentRoles));
    },
    [Constants.SET_TOKEN](state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      axiosConnection.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    },
    [Constants.SET_NEW_TERM](state, term) {
      state.term = term;
    },
    [Constants.SET_USER_ROLE_ID](state, userRoleId) {
      state.userRoleId = userRoleId;
    },
    [Constants.MOBILE_LOGIN](state, data) {
      localStorage.setItem("token", data.token);
      state.token = data.token;
      state.version = data.version;
    },
    [Constants.SET_AS_NATIVE](state) {
      state.native = true;
    },
    [Constants.TERM_UPDATED](state) {
      state.term = null;
    },
    [Constants.SET_SHOW_CONGRATS_PAGE](state, bool) {
      state.show_congrats_page = bool;
    },
    [Constants.UPDATE_FIELD](state, params) {
      if (params.type === "staff") {
        if (state.user.is_staff) {
          state.user[params.field] = params.value;
        } else if (state.subAccount && state.subAccount.is_staff) {
          state.subAccount[params.field] = params.value;
        }
      } else if (params.type === "parent") {
        if (state.user.is_staff == 0) {
          state.user[params.field] = params.value;
        } else if (state.subAccount && state.subAccount.is_staff == 0) {
          state.subAccount[params.field] = params.value;
        }
      }
    },
    [Constants.UPDATE_AVATAR](state, image) {
      state.user = Object.assign({}, state.user, {
        ["profile_photo_url"]: image,
      });
      // this.$set(state.user, "profile_photo_url", "test");
    },
    [Constants.UPDATE_SCHOOLRUN_CACHE](state, newData) {
      state.schoolrunFeatures = newData;
    },
    [Constants.SET_YEAR_LABELS](state, newData) {
      state.yearLabels = newData;
    },
    initialiseStore(state) {
      if (localStorage.getItem("token")) {
        const token = localStorage.getItem("token");
        const user_id = localStorage.getItem("user_id");
        const parent_id = localStorage.getItem("parent_id");
        state.token = token;
        state.user_id = parseInt(user_id);
        if (parent_id) {
          state.parent_id = parseInt(parent_id);
        }
        axiosConnection.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        // Replace the state object with the stored item
        // this.replaceState(
        //   //   Object.assign(state, JSON.parse(localStorage.getItem("store")))
        //   Object.assign(state, localStorage.getItem("token"))
        // );
      }
    },
  },
  actions: {
    async attemptLogin({ commit }, payload) {
      try {
        commit(Constants.LOADING, true);
        const result = await ApiService.generalApi.login(payload);
        const adminRole = result.data.roles
          .filter(((itm) => itm.admin_role == 'YES')).length;
        const userRole = result.data.roles
          .filter(((itm) => itm.admin_role == 'NO')).length;
        if (!userRole && adminRole) {
          Notify({
            type: "warning",
            message: "School admins, please log in at admin.homerun-app.com",
          });
          commit(Constants.LOADING, false);
          return;
        }
        commit(Constants.SET_USER_ROLE_ID, null);
        commit(Constants.LOGGED_IN, result.data);
        commit(Constants.LOADING, false);
        if (sessionStorage.getItem('redirect') == 'enter_email') {
          router.push("/registration_mi/email/" + sessionStorage.getItem('accType'));
          return;
        } else if (sessionStorage.getItem('redirect') == 'registration_mi') {
          router.push("/registration_mi/" + sessionStorage.getItem('schoolCode') + '/school');
          return;
        } else if (sessionStorage.getItem('redirect') == 'registration_mi/onboarding' && sessionStorage.getItem('hash')) {
          // check if it's correct
          router.push("/signup/" + sessionStorage.getItem('hash'));
          return;
        }
        if (payload.schoolCode) {
          router.push("/flow/details").catch(()=>{});
        } else {
          router.push("/main/home");
        }
      } catch (e) {

        if (e.response.status === 401) {
          commit(Constants.LOADING, false);
          Notify({
            type: "warning",
            message: "Wrong credentials",
          });
        } else {
          alert(e);
        }
      }
    },
    async updateUserInfo({ commit }, payload) {
      try {
        commit(Constants.UPDATE_USER_INFO, payload);
      } catch (e) {
        alert(e);
        if (e.response === 401) {
          Notify({
            type: "warning",
            message: "Error while updating user info",
          });
        }
      }
    },
    async saveLoginData({ commit }, payload) {
      commit(Constants.LOGGED_IN, payload);
    },
    logout({ commit }) {
      commit(Constants.LOGGED_OUT, null, { root: true });
    },
    loading({ commit }, bool) {
      commit(Constants.LOADING, bool);
    },
    setRefreshMessages({ commit }, bool) {
      commit(Constants.REFRESH_MESSAGES, bool);
    },
    addSchoolCode({ commit }, code) {
      commit(Constants.SCHOOL_CODE, code);
    },
    addParent2Token({ commit }, payload) {
      commit(Constants.PARENT2_TOKEN, payload);
    },
    register({ commit }, data) {
      commit(Constants.LOGGED_IN, data);
    },
    setToken({ commit }, token) {
      commit(Constants.SET_TOKEN, token);
    },
    setUserRoleId({ commit }, user_role_id) {
      commit(Constants.SET_USER_ROLE_ID, user_role_id);
    },
    setTerm({ commit }, term) {
      commit(Constants.SET_NEW_TERM, term);
    },
    loginViaMobileApp({ commit }, data) {
      commit(Constants.LOGGED_IN, data);
    },
    setToNative({ commit }) {
      commit(Constants.SET_AS_NATIVE);
    },
    updateAvatar({ commit }, image) {
      commit(Constants.UPDATE_AVATAR, image);
    },
    updateJourneyPreference({ commit }, journey_preference) {
      commit(Constants.UPDATE_JOURNEY_PREFERENCE, journey_preference);
    },
    updateUserRoleSetting({ commit }, payload) {
      commit(Constants.UPDATE_USER_ROLE_SETTING, payload)
    },
    termUpdated({ commit }) {
      commit(Constants.TERM_UPDATED);
    },
    updateField({ commit }, params) {
      commit(Constants.UPDATE_FIELD, params);
    },
    updateSchoolrunFeatures({ commit }, data) {
      commit(Constants.UPDATE_SCHOOLRUN_CACHE, data);
    },
    setYearLabels({ commit }, obj) {
      commit(Constants.SET_YEAR_LABELS, obj);
    },
    setShowCongratsPage({ commit }, bool) {
      commit(Constants.SET_SHOW_CONGRATS_PAGE, bool);
    },
  },
};
