<style>
div#codeInputs input {
  width: 40px;
  height: 60px;
  margin-right: 20px;
  border-radius: 15px;
  padding: 0px !important;
  font-size: 30px;
  text-align: center;
  border: 2px solid black;
}
</style>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<template>
  <div id="registrationMiWrapper" class="clearfix">
    <div class="" style="min-height: 80px; position: relative">
      <MiFlowHeader
        page_title="Let’s get you verified!"
        :logo="schoolInfo.logo"
      />
      <button
        class="blankButton"
        v-if="loggedIn"
        @click="logout"
        style="position: absolute; right: 0px; top: 18px"
      >
        <i class="fa-light fa-arrow-right-from-bracket"></i><br /><span
          class="colorGrey"
          >Logout</span
        >
      </button>
    </div>
    <div v-if="!email" class="p-2">
      Something seems to have gone wrong, please close this tab and re-open your
      sign up link.
    </div>
    <div id="flexDiv" style="min-height: 450px" v-if="email">
      <div>
        <p class="mialert lightGreen text-left">
          We've sent an email to <b>{{ email }}</b> containing a verification
          code. Please enter the code below to verify your email address.
        </p>
      </div>
      <div class="row">
        <div id="codeInputs" class="col-xs-12 text-center">
          <p>Enter code</p>
          <!-- <PincodeInput v-model="code" placeholder="-" length="5" /> -->
          <!-- <PasswordInput :value="code" :mask="false" /> -->
          <div>
            <input
              id="c1"
              ref="c1"
              name="c1"
              v-model="c1"
              data-cy="c1_input"
              autocomplete="off"
              @keypress="focusInput($event, 'c2')"
              @click="selectInputText('c1')"
              @paste="onPaste"
              type="number"
              pattern="[0-9]{1}"
              maxlength="1"
            />
            <input
              id="c2"
              ref="c2"
              v-model="c2"
              autocomplete="off"
              data-cy="c2_input"
              @keypress="focusInput($event, 'c3')"
              @keydown="backSpace($event, 'c1')"
              @click="selectInputText('c2')"
              @paste="onPaste"
              type="number"
              pattern="[0-9]{1}"
              maxlength="1"
            />
            <input
              id="c3"
              ref="c3"
              v-model="c3"
              autocomplete="off"
              data-cy="c3_input"
              @keypress="focusInput($event, 'c4')"
              @keydown="backSpace($event, 'c2')"
              @click="selectInputText('c3')"
              @paste="onPaste"
              type="number"
              pattern="[0-9]{1}"
              maxlength="1"
            />
            <input
              id="c4"
              ref="c4"
              v-model="c4"
              autocomplete="off"
              data-cy="c4_input"
              @keypress="focusInput($event, 'c5')"
              @click="selectInputText('c4')"
              @keydown="backSpace($event, 'c3')"
              @paste="onPaste"
              type="number"
              pattern="[0-9]{1}"
              maxlength="1"
            />
            <input
              id="c5"
              ref="c5"
              v-model="c5"
              autocomplete="off"
              data-cy="c5_input"
              @click="selectInputText($event, 'c5')"
              @keydown="backSpace($event, 'c4')"
              @keypress="focusInput($event, 'c5')"
              @paste="onPaste"
              type="number"
              pattern="[0-9]{1}"
              maxlength="1"
            />
          </div>
          <!-- <van-field
            label="Enter code:"
            data-cy="code_input"
            class="roundInput"
            required
            type="digit"
            name="Verification Code"
            :placeholder="placeholder"
            v-model="code"
            maxlength="4"
            label-width="100"
            style="
              border: 1px solid #ccc;
              font-size: 16px;
              max-width: 235px;
              margin: auto;
            "
          ></van-field> -->
        </div>
      </div>

      <div class="row flowBottomButtonDiv">
        <div class="col-xs-12">
          <!-- <van-button type="info" @click="$router.go(-1)" plain>Go Back</van-button> -->
          <van-button
            type="info"
            data-cy="submit_btn"
            name="button"
            @click="submit()"
            :disabled="!code || code.length != 5"
            :loading="loading"
            class="blackBtn"
            >Confirm</van-button
          >
        </div>
      </div>
      <div class="mialert lightGray">
        <p style="margin-bottom: 0px">
          If you haven't received the code, please first check your junk / spam
          folder.
        </p>
        <div class="text-center">
          <van-button
            type="info"
            data-cy="resend_btn"
            plain
            size="small"
            small
            @click="resendCode"
            style="
              width: 120px;
              border: none !important;
              color: black;
              font-weight: 500;
            "
            >Re-send code</van-button
          >
        </div>
        <p>
          If you are still having issues, please email
          <a style="color: #9cc200" href="mailto:info@homerun-app.com"
            >info@homerun-app.com</a
          >
        </p>
      </div>
      <div id="bottomLogo" class="text-center" style="margin-top: 20px">
        <img
          src="@/assets/wireframe_logo.svg"
          style="vertical-align: baseline"
        />
      </div>
      <modal
        name="existingUserModal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
      >
        <!-- <ModalHeader
        title="Select bus type ?"
        icon="fa fa-bus"
        @hideModal="
          hideModal('busTypeModal');
          resetSelection();
        "
      /> -->
        <div class="col-xs-12">
          <div
            class="row"
            style="margin-bottom: 50px; margin-top: 50px; text-align: center"
          >
            <p class="mialert lightGreen">
              Oh! It looks like you've already got a HomeRun account. You'll
              need to login in order to join {{ schoolInfo.name }} network.
            </p>
          </div>
          <div class="text-center" style="margin-bottom: 50px">
            <hr style="margin-bottom: 50px" />
            <van-button type="info" class="blackBtn" @click="login()"
              >Login</van-button
            >
          </div>
        </div>
        <div>
          <van-button
            type="info"
            class="greyBtn"
            @click="hideModal('existingUserModal')"
            plain
            >Go Back</van-button
          >
        </div>
      </modal>
      <modal
        name="existingUserModal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
      >
        <!-- <ModalHeader
        title="Select bus type ?"
        icon="fa fa-bus"
        @hideModal="
          hideModal('busTypeModal');
          resetSelection();
        "
      /> -->
        <div class="col-xs-12">
          <div>
            <p class="mialert lightRed">
              You cannot add that email address to your account becuase it is
              registered to a different user. <br /><br />Please email
              info@homerun-app.com if you believe you have two separate HomeRun
              accounts that you'd like to combine.
            </p>
          </div>
        </div>
        <div>
          <van-button type="info" @click="hideModal('existingUserModal')" plain
            >Go Back</van-button
          >
        </div>
      </modal>
      <modal
        name="unrecognisedEmailModal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
      >
        <!-- <ModalHeader
        title="Select bus type ?"
        icon="fa fa-bus"
        @hideModal="
          hideModal('busTypeModal');
          resetSelection();
        "
      /> -->
        <div class="col-xs-12">
          <div>
            <p class="mialert lightRed">
              The email address {email address} has not been recognised as
              belonging to a parent or staff member at {School Name}. You can go
              back and try a different email address.<br /><br />
              If you are still having difficulty, please contact the school to
              confirm that the email address they have for you is correct and
              then let us know at info@homerun-app.com
            </p>
          </div>
        </div>
        <div>
          <van-button type="info" @click="hideModal('existingUserModal')" plain
            >Back</van-button
          >
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";
import { M } from "@chenfengyuan/vue-qrcode";
// import PincodeInput from "vue-pincode-input";

export default {
  name: "verificationEmail",
  components: {
    MiFlowHeader,
    // PincodeInput,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      existingUserModal: false,
      duplicateErrorModal: false,
      unrecognisedEmailModal: false,
      placeholder: "Enter Code",
      unvalidated: null,
      loading: false,
      c1: "",
      c2: "",
      c3: "",
      c4: "",
      c5: "",
      email: "",
      schoolInfo: {
        name: "",
        logo: "",
      },
    };
  },
  computed: {
    loggedIn() {
      if (!localStorage.token) {
        return false;
      } else {
        return true;
      }
    },
    code() {
      return this.c1 + this.c2 + this.c3 + this.c4 + this.c5;
    },
  },
  watch: {
    c1(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c1 = newVal;
      }
    },
    c2(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c2 = newVal;
      }
    },
    c3(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c3 = newVal;
      }
    },
    c4(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c4 = newVal;
      }
    },
    c5(val, oldVal) {
      if (val.length > 1) {
        let newVal = val[0] == oldVal[0] ? val[1] : val[0];
        this.c5 = newVal;
      }
    },
  },
  methods: {
    submit() {
      this.loading = true;
      const email = sessionStorage.getItem("email");
      const data = {
        email: sessionStorage.getItem("email"),
        school_id: sessionStorage.getItem("school_id"),
        main_school_id: sessionStorage.getItem("main_school_id"),
        accType: sessionStorage.getItem("accType"),
        getMisDetails: true,
        code: this.code,
      };
      ApiService.generalApi
        .verifyEmail(data)
        .then((resp) => {
          const responseStatus = resp.data.status
            ? resp.data.status
            : resp.data;

          let missingAccEmail = null;
          let currentRegistrationcodes = sessionStorage.getItem(
            "registration_codes"
          )
            ? JSON.parse(sessionStorage.getItem("registration_codes"))
            : [];
          if (responseStatus == "ok" || responseStatus == 1) {
            currentRegistrationcodes.forEach((itm, index) => {
              if (itm.email == email) {
                currentRegistrationcodes[index].code = this.code;
              } else {
                missingAccEmail = itm.type;
              }
            });

            sessionStorage.setItem(
              "registration_codes",
              JSON.stringify(currentRegistrationcodes)
            );

            // if (missingAccEmail == "parent") {
            //   // add parent account since there's only 1 staff account
            //   sessionStorage.setItem("accType", "parent");
            //   this.$router.push("/registration_mi/email/parent");
            //   return;
            // }
            if (resp.data.first_name) {
              sessionStorage.setItem("title", resp.data.title);
              sessionStorage.setItem("first_name", resp.data.first_name);
              sessionStorage.setItem("last_name", resp.data.last_name);
            }

            if (
              data.accType == "both" &&
              currentRegistrationcodes.length == 1
            ) {
              this.$router.push("/registration_mi/email/parent");
              return;
            } else {
              this.$router.push("/registration_mi/new_account");
            }
          } else if (responseStatus == "no_matches") {
            this.$notify({
              type: "danger",
              message: "The code you have entered is incorrect.",
              duration: 3000,
            });
          } else if (responseStatus == "expired") {
            this.$notify({
              type: "danger",
              message: "Your code has expired",
              duration: 3000,
            });
          } else if (responseStatus == "redirect_to_flow") {
            sessionStorage.clear();
            this.$router.push("/flow/details").catch(()=>{});
          }
        })
        .catch((e) => {
          const errorMessage = e?.response?.data?.error;
          if (errorMessage == "wrong_code") {
            this.$notify({
              type: "danger",
              message: "The code you have entered is incorrect.",
              duration: 3000,
            });
          } else if (errorMessage == "code_expired") {
            this.$notify({
              type: "danger",
              message: "Your code has expired",
              duration: 3000,
            });
          }
          // console.log("E", e.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resendCode() {
      const data = {
        email: sessionStorage.getItem("email"),
        main_school_id: sessionStorage.getItem("main_school_id"),
        school_id: sessionStorage.getItem("school_id"),
        accType: sessionStorage.getItem("accType"),
      };
      ApiService.generalApi
        .resendCode(data)
        .then((resp) => {
          const status = resp.data;
          this.$notify({
            type: "success",
            message: "Verification Email Resent",
          });
        })
        .catch((e) => {
          alert(e.data);
        });
    },
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    hideModal(modalName) {
      this.$modal.hide(modalName);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/login");
      this.$notify({
        type: "success",
        message: "You have been logged out",
      });
    },
    focusInput($event, inputName) {
      this.filterKey($event);
      // console.log("event", $event);
      if (this.isInt($event.key)) {
        setTimeout(() => {
          this.$refs[inputName].focus();
          // this.$refs[inputName].select();
        }, 20);
      } else {
        $event.target.value = "";
        // $event
      }
    },
    filterKey(e) {
      let previousValue = e.target.value;
      let key = e.keyCode || e.charCode;
      // console.log("E", e);
      // console.log("KEY", key);
      if (key != 8 && key != 46 && key != 91 && key != 86) {
        // Ignore backspace and delete
        if (
          // preventDefault if length of input is 4 and no text is selected in the input
          (String(e.target.value).length >= 4 &&
            e.target.selectionStart === e.target.selectionEnd) ||
          // preventDefault if entered a space or non-number
          !e.key.trim() ||
          isNaN(e.key)
        ) {
          console.log("key", key);
          // Prevent input if input length crosses 4,
          // or if input is not a number
          e.preventDefault();
          // Include below line only if you have no other event listeners on the element, or its parents
          e.stopImmediatePropagation();
          return;
        }
      }
    },
    backSpace(e, prevInputName) {
      let key = e.keyCode || e.charCode;
      if (key == 8 && e.target.value == "") {
        //
        this.$nextTick(() => {
          this[prevInputName] = "";
          this.$refs[prevInputName].focus();
          this.$refs[prevInputName].setSelectionRange(0, 0);
          // this.$refs[prevInputName].select();
        });
      }
    },
    selectInputText(inputName) {
      // this.$refs[inputName].setSelectionRange(0, 0);
    },
    isInt(value) {
      return (
        !isNaN(value) &&
        (function (x) {
          return (x | 0) === x;
        })(parseFloat(value))
      );
    },
    onPaste(evt) {
      console.log("on paste", evt);
      const pastedText = evt.clipboardData.getData("text");
      const letters = pastedText.split("");
      letters.forEach((l, index) => {
        if (index < 5 && this.isInt(l)) {
          this["c" + (index + 1)] = l;
        }
      });
    },
  },
  mounted() {
    this.email = sessionStorage.getItem("email");
    if (sessionStorage.getItem("schoolName")) {
      this.schoolInfo.name = sessionStorage.getItem("schoolName");
      this.schoolInfo.logo = sessionStorage.getItem("logo");
    }
  },
};
</script>
