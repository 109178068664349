<template>
  <div></div>
</template>
<script>
import ApiService from "@/services/ApiService";

export default {
  name: "redirect",
  computed: {
    native() {
      return this.$store.state.general.native;
    },
    loading() {
      return this.$store.state.general.loading;
    },
  },
  // async beforeRouteEnter(to, from, next) {
  //   const result = await ApiService.userApi.getUserInfo();
  //   console.log("main template mounted");
  //   this.$store.dispatch("updateUserInfo", result.data);
  //   next();
  // },
  methods: {
    async getUserInfo() {
      try {
        const result = await ApiService.userApi.getUserInfo();
        this.$store.dispatch("updateUserInfo", result.data);
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push({ name: "login" });
        }
      }
    },
    tryStopLoading() {
      const timeAfter10s = Math.round(new Date().getTime() / 1000) - 10;
      if (timeAfter10s > this.$store.state.general.loading_started) {
        this.$store.dispatch("loading", false);
      }
    },
  },
  created() {
    try {
      this.getUserInfo();
      setTimeout(() => {
        ApiService.userApi
          .accountStatusRedirect()
          .then((resp) => {
            if (resp == true) {
              if (sessionStorage.getItem("redirect")) {
                const redirectPath = sessionStorage.getItem("redirect");
                sessionStorage.removeItem("redirect");
                this.$router.push(redirectPath).catch(()=>{});
              } else {
                this.$router.push("/main/home");
              }
            }
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              (err.response.data.error === "No user has been found" ||
                err.response.data.message == "Token has expired" ||
                err.response.data.message == "TokenInvalidException")
            ) {
              if (this.$store.state.general.native) {
                window.postMessage("logout", "*");
              } else {
                this.$router.push({ name: "logout" });
              }
            } else {
              alert(err);
            }
          });
      }, 500);
    } catch (error) {
      alert(error);
    }
  },
};
</script>
