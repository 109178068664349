<template>
  <!-- Event -->
  <div @click="noticeEvent('showNotice')">
    <div class="notice-header">
      <block-title @noticeEvent="noticeEvent" :message="message" />
      <div class="notice-category bg-notice-theme">
        <i
          v-if="message.category === 'pta'"
          class="fat fa-user-group-simple"
        ></i>
        <i v-if="message.category === 'eco'" class="fat fa-leaf"></i>
        <i
          v-if="message.category === 'sports'"
          class="fat fa-field-hockey-stick-ball"
        ></i>
        <i v-if="message.category === 'general'" class="fat fa-newspaper"></i>
        <i v-if="message.category === 'travel'" class="fat fa-location-pin"></i>
        <div class="category-details">
          <div>
            <span class="uppercase"
              >{{ message.category === "sports" ? "Sports" : message.category }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <block-title @noticeEvent="noticeEvent" :message="message" /> -->
    <div class="category-years">
      <span
        class="yearBlock pull-right"
        v-if="message.years && yearNames == ''"
      >
        Years:
        {{ message.years }}
      </span>
      <span
        class="yearBlock pull-right"
        v-if="message.years && yearNames != ''"
      >
        Years:
        {{ yearNames }}
      </span>
      <span
        class="yearBlock pull-right"
        v-if="!message.years && !message.class_label"
      >
        Years: All
      </span>
      <span class="yearBlock pull-right" v-if="message.class_label">
        {{ message.class_label }}
      </span>
    </div>
    <div
      class="blockText noticeText eventMiddle notice-body"
      style="position: relative"
    >
      <p style="text-align: center">
        <i
          class="far fa-calendar-alt"
          style="font-size: 23px; margin-bottom: 8px"
        ></i>
        &nbsp;&nbsp;
        <span style="letter-spacing: 1px">
          <b>EVENT</b>
        </span>
        <br />
        <b>{{ message.title }}</b>
        <br />
        <b class="eventDate">{{ message.extras.date }}</b>
      </p>
      <div class="insideRect"></div>
    </div>
    <div>
      <p
        v-if="message.type == 'event'"
        class="blockText noticeText"
        style="margin-top: 0px"
        v-html="message.message"
      ></p>
    </div>
    <div>
      <span class="seeMoreLabel p-1 px-2" style>See more</span>
    </div>
    <NoticeFooter :message="message" @noticeEvent="noticeEvent" />
  </div>
</template>
<script>
import BlockTitle from "@/components/noticeTypes/other/BlockTitle";
import NoticeFooter from "@/components/noticeTypes/other/NoticeFooter";
export default {
  name: "event",
  components: {
    BlockTitle,
    NoticeFooter,
  },
  props: {
    message: Object,
  },
  data() {
    return {
      yearNames: "",
    };
  },
  methods: {
    noticeEvent(type) {
      this.$emit("noticeEvent", {
        action: type,
        id: this.message.id,
        user_id: this.message.user_id,
      });
    },
  },
  mounted() {
    if (
      this.message.years != "All" &&
      this.message.years != "" &&
      this.message.years != null
    ) {
      let yearNames = [];
      const yearLabels = this.$store.state.general.yearLabels;
      if (yearLabels) {
        const separateYears = this.message.years.split(",");
        separateYears.forEach((year) => {
          try {
            yearNames.push(
              yearLabels[parseInt(this.message.extras.school)][parseInt(year)]
            );
          } catch (error) {
            yearNames.push(year);
          }
        });
        this.yearNames = yearNames.join(", ");
      }
    }
  },
};
</script>
